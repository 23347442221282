// Aletris Pinkroot 2021
/*
	Official aletris pinkroot website written in Gatsby.
*/
import React from 'react';
import Navbar from '../components/Navbar';
import Shop from '../components/Shop';
import Splash from '../components/Splash';
import { Helmet } from 'react-helmet';
import Footer from '../components/Footer';
import favicon from '../images/favicon.png';
import '../constants/responsive.scss';

class index extends React.Component {
	render() {
		return (
			<>
				<Helmet defer>
					<title>Aletris Pinkroot Shop | Official Store</title>
					<meta
						name='title'
						content='Aletris Pinkroot Shop | Official Store'
					/>
					<meta
						name='description'
						content='The official store website for Aletris Pinkroot, an Australian alternative pop artist.'
					/>
					<meta
						name='keywords'
						content='pop, music, art, artist, alternative, electronic, country, indie, acoustic'
					/>
					<meta name='robots' content='index, follow' />
					<meta
						http-equiv='Content-Type'
						content='text/html; charset=utf-8'
					/>
					<link
						rel='icon'
						type='image/png'
						href={'https://shop.aletrispinkroot.com/' + favicon}
						sizes='16x16'
					/>
					<meta name='language' content='English' />
					<meta name='revisit-after' content='50 days' />
					{/*<!-- Open Graph / Facebook -->*/}
					<meta property='og:type' content='website' />
					<meta
						property='og:url'
						content={`https://aletrispinkroot.com`}
					/>
					<meta property='og:title' content={'Aletris Pinkroot'} />
					<meta
						property='og:description'
						content={`The official store website for Aletris Pinkroot, an Australian alternative pop artist.`}
					/>
					<meta
						property='og:image'
						content={'https://shop.aletrispinkroot.com'}
					/>
					{/* <!-- Twitter --> */}
					<meta
						property='twitter:card'
						content='summary_large_image'
					/>
					<meta
						property='twitter:url'
						content={`https://shop.aletrispinkroot.com`}
					/>
					<meta
						property='twitter:title'
						content={'Aletris Pinkroot'}
					/>
					<meta
						property='twitter:description'
						content={`The official store website for Aletris Pinkroot, an Australian alternative pop artist.`}
					/>
					<meta
						property='twitter:image'
						content={'https://shop.aletrispinkroot.com'}></meta>
				</Helmet>
				<main style={{ margin: 0, padding: 0 }}>
					<Navbar />
					<Splash />

					<Shop />
					<Footer />
				</main>
			</>
		);
	}
}

export default index;
