import React from 'react';
import v from '../constants/rules';

function Store(props) {
	const storeDiv = React.useRef(null);
	const scriptRef = React.useRef(null);

	// window.localStorage.setItem('show_ecwid_logs', 'true');
	// window.ecwid_script_defer = true;
	// window.ecwid_dynamic_widgets = true;
	// window.ec = window.ec || Object();
	// window.ec.storefront = window.ec.storefront || Object();
	// window.ec.enable_catalog_on_one_page = true;
	// window._xnext_initialization_scripts = [
	// 	{
	// 		widgetType: 'ProductBrowser',
	// 		id: 'my-store-45905167',
	// 		arg: ['id=productBrowser', 'views=grid(20,3)'],
	// 	},
	// ];

	// var script = document.createElement('script');
	// script.charset = 'utf-8';
	// script.type = 'text/javascript';
	// script.src = 'https://app.ecwid.com/script.js?45905167';
	// script.defer = true;
	// script.ref = scriptRef;

	React.useEffect(() => {
		window.localStorage.setItem('show_ecwid_logs', 'true');
		window.ecwid_script_defer = true;
		window.ecwid_dynamic_widgets = true;
		window.ec = window.ec || Object();
		window.ec.storefront = window.ec.storefront || Object();
		window.ec.enable_catalog_on_one_page = true;
		window._xnext_initialization_scripts = [
			{
				widgetType: 'ProductBrowser',
				id: 'my-store-45905167',
				arg: ['id=productBrowser', 'views=grid(20,3)'],
			},
		];

		let script = document.createElement('script');
		script.charset = 'utf-8';
		script.type = 'text/javascript';
		script.src = 'https://app.ecwid.com/script.js?45905167';
		script.defer = true;
		script.ref = scriptRef;
		if (!scriptRef.current) {
			storeDiv.current.appendChild(script);
		}
	});

	return (
		<div
			style={{
				paddingTop: '6em',
				zIndex: -2,
				background: v.white,
				color: v.black,
				fontFamily: 'acumin-pro',
			}}>
			<div id='my-store-45905167' ref={storeDiv}></div>
			<div className='ec-cart-widget'></div>
		</div>
	);
}

export default Store;
