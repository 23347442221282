import { StaticImage } from 'gatsby-plugin-image';

import React from 'react';
import styled from 'styled-components';
import v from '../constants/rules';

type SplashProps = {};

export default class Splash extends React.Component<SplashProps> {
	render() {
		return (
			<div
				id='home'
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '50vh',
				}}>
				<div
					className='clip'
					style={{
						maxWidth: '100vw',
						maxHeight: '100vh',
						overflow: 'hidden',
						position: 'absolute',
						zIndex: -500,
						top: 0,
						right: 0,
						left: 0,
					}}>
					<StaticImage
						src={'../images/nighttrees.jpg'}
						alt='Night sky and trees'
						placeholder='dominantColor'
						style={{
							height: '100vh',
							position: 'relative',
							top: 0,
						}}
					/>
				</div>
				{/* <SplashText id='splash' href={this.props.link}>
					{this.props.text}
					<br />
					<span>{this.props.subtext}</span>
				</SplashText> */}
			</div>
		);
	}
}
